import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Collapse, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { userSignOut } from 'actions/Auth';
import AvatarIcon from 'components/organisms/Headers/ProfileHeader/AvatarIcon';
import IntlMessages from 'util/IntlMessages';

const ICON_SIZE = 45;

function UserInfo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.authUser);
  const [showMenu, setShowMenu] = useState(false);

  const navigate = (route) => {
    history.push(`/app/${route}`);
    setShowMenu(false);
  };

  const handleLogout = () => {
    setShowMenu(false);
    dispatch(userSignOut());
  };

  const associatedMerchantSlug = user?.merchants?.[0];
  const isMerchantAdmin = user?.roles?.includes('merchant_admin');

  return (
    <section className="user-profile">
      <div
        className="d-flex align-items-center justify-content-around w-100 px-2 pt-1"
        onClick={() => setShowMenu((state) => !state)}
      >
        <AvatarIcon
          type={user?.type === 'merchant' ? 'merchant' : 'default'}
          data={{ slug: associatedMerchantSlug, active: true }}
          size={ICON_SIZE}
        />
        <div className="user-detail">
          <Tooltip title={user?.name}>
            <h4 className="user-name d-flex justify-content-around">
              <span className="text-truncate">{user?.name}</span>
            </h4>
          </Tooltip>
          <h4 className="user-role d-flex text-truncate text-capitalize">
            {user?.type === 'administrator'
              ? 'Admin'
              : isMerchantAdmin
              ? 'Merchant Admin'
              : user?.type}
          </h4>
          <h4 className="user-email d-flex">
            <Tooltip title={user?.email ?? ''}>
              <span className="text-truncate">{user?.email}</span>
            </Tooltip>
          </h4>
        </div>
        <i
          className={`zmdi zmdi-chevron-down zmdi-hc-lg align-middle transition-all-3 ${showMenu &&
            'wi-rotate-180'}`}
        />
      </div>

      <Collapse
        in={showMenu}
        onClick={(e) => e.stopPropagation()}
        className="menu-collapse"
      >
        <MenuItem
          onClick={() => navigate(`profile/${user.id}`)}
          className="menu-item"
        >
          <i className="zmdi zmdi-account zmdi-hc-lg mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={handleLogout} className="menu-item">
          <i className="zmdi zmdi-sign-in zmdi-hc-lg mr-2" />
          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Collapse>
    </section>
  );
}

export default UserInfo;
