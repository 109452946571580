import React from 'react';

import CustomScrollbars from 'components/molecules/CustomScrollBars';

import languageData from './data';
import LanguageItem from './LanguageItem';

const LanguageSwitcher = ({ switchLanguage, handleRequestClose }) => {
  const minHeight = 120;

  return (
    <CustomScrollbars
      className='messages-list language-list scrollbar text-black-50'
      style={{ height: minHeight }}
    >
      <ul className='list-unstyled'>
        {languageData.map((language, index) => (
          <LanguageItem
            key={index}
            language={language}
            handleRequestClose={handleRequestClose}
            switchLanguage={switchLanguage}
          />
        ))}
      </ul>
    </CustomScrollbars>
  );
};

export default LanguageSwitcher;
