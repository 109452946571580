import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import CodeInputField from 'components/atoms/FormFields/CodeInputField';
import { TWO_FA_CODE_DIVIDER_IDX, TWO_FA_CODE_LENGTH } from 'constants/Misc';
import IntlMessages from 'util/IntlMessages';

const THREE_SECONDS = 3000;
const QR_CODE_SIZE = 160;

function TwoFaRequired({
  handleClose,
  handleSkip,
  isFetching,
  onSubmit,
  twoFaData,
  hideSkip,
}) {
  const [wasCopied, setWasCopied] = useState(false);
  const [code, setCode] = useState(Array(TWO_FA_CODE_LENGTH).fill(''));
  const handleClipboard = () => {
    navigator.clipboard.writeText(twoFaData?.secret);
    setWasCopied(true);
    setTimeout(() => setWasCopied(false), THREE_SECONDS);
  };

  const shouldDisableButton =
    code
      .flat(Infinity)
      .join('')
      .trim().length !== TWO_FA_CODE_LENGTH;

  return (
    <div className="two-fa-modal py-3 px-4">
      <i
        class="zmdi zmdi-close absolute-top-right p-3 pointer"
        onClick={handleClose}
      />
      <p className="m-0 font-family-bold widget-title mt-4">
        <IntlMessages id="component.twoFaSetup.title" />
      </p>
      <p className="m-0 font-family-bold text-vpag-primary mb-2 text-lowercase">
        <IntlMessages id="component.passwordMananger.2fa" />
      </p>
      <p className="jr-fs-sm arboria-regular text-gray-light m-0">
        <IntlMessages id="component.passwordMananger.2faGenerate.instructions" />
      </p>
      <div className="d-flex align-items-center mt-2">
        <h3 className="text-uppercase font-family-bold my-2 text-white">
          {twoFaData?.secret}
        </h3>
        <button
          className="border-0 bg-transparent text-vpag-primary ml-2"
          onClick={handleClipboard}
          disabled={wasCopied}
        >
          <i className="zmdi zmdi-copy jr-fs-xl text-vpag-primary" />
        </button>
      </div>
      <QRCode
        value={twoFaData?.qrCode}
        includeMargin
        size={QR_CODE_SIZE}
        className="qr-code my-4"
      />
      <p className="jr-fs-sm font-family-bold text-white mt-1 mb-4">
        <IntlMessages id="component.twoFaSetup.instructions" />
      </p>
      <form>
        <CodeInputField
          code={code}
          length={TWO_FA_CODE_LENGTH}
          setCode={setCode}
          codeDividerIndex={TWO_FA_CODE_DIVIDER_IDX}
        />
        <div className="d-flex gap-2">
          {hideSkip ? (
            <button type="button" className="text-button" onClick={handleClose}>
              <IntlMessages id="app.general.actions.close" />
            </button>
          ) : (
            <button type="button" className="text-button" onClick={handleSkip}>
              <IntlMessages id="app.general.actions.skip" />
            </button>
          )}

          <button
            className="two-fa-modal__copy-btn mt-4 mb-3"
            onClick={() => onSubmit(code.join(''))}
            disabled={shouldDisableButton || isFetching}
          >
            <IntlMessages id="app.general.actions.configure" />
          </button>
        </div>
      </form>
    </div>
  );
}

TwoFaRequired.propTypes = {
  handleClose: PropTypes.func,
  handleSkip: PropTypes.func,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  twoFaData: PropTypes.shape({
    secret: PropTypes.string,
    qrCode: PropTypes.string,
  }),
};

export default TwoFaRequired;
