import { api } from './config';

//   ---------- USER -----------

export const signIn = async (data) => {
  const request = await api
    .post('/user/auth/login', data)
    .then((res) => res.data);

  return request;
};

export const getUsers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/user${filter}`);
};

export const getUsernames = async (queries) => {
  return api.get(`/user/names?${queries}`);
};
export const getUsersByIds = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/user/names${filter}`).then((res) => res.data);
};

export const exportUsers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/user/export/csv${filter}`);
};

export const getMerchantUsers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/user/merchants${filter}`);
};

export const getMerchantUserById = async (userId) => {
  return api.get(`/user/merchants/${userId}`).then((res) => res.data);
};

export const updateMerchantUserById = async (userId, payload) => {
  return api
    .patch(`/user/merchants/${userId}`, payload)
    .then((res) => res.data);
};

export const exportMerchantUsers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/user/merchants/export/csv${filter}`);
};

export const getUserData = async (id) =>
  api.get(`/user/${id}`).then((res) => res);

export const editUserData = async (id, data) =>
  api.patch(`/user/${id}`, data).then((res) => res);

export const createUser = async (payload) =>
  api.post('/user', payload).then((res) => res);

export const fitbankManualTransaction = async (payload) =>
  api.post('/banks/transactions/manual/fitbank', payload).then((res) => res);

export const getBankInfo = async (params, signal) =>
  await api
    .get('/banks/get-transaction-info', { params, signal })
    .then((res) => res.data);

export const createMerchantUser = async (payload) =>
  api.post('/user/merchants', payload).then((res) => res);

export const updateUserPassword = async (payload) =>
  api.patch('/user/password/update', payload).then((res) => res.data);

export const requestRecoverPassword = async (payload) =>
  api.post('/user/password/recover', payload).then((res) => res.data);

export const updatePasswordWithToken = async (payload) =>
  api.patch('/user/password/recover', payload).then((res) => res.data);

export const assignMerchant = async (userId, payload) =>
  api.put(`/user/merchants/${userId}/assign`, payload).then((res) => res);

export const getUserRoles = async (id, signal) =>
  api.get(`/user/${id}/roles`, { signal }).then((res) => res.data);

export const getAvailableRoles = async (signal) =>
  api.get(`/user/roles`, { signal }).then((res) => res);

export const updateUserRoles = async (id, payload) =>
  api.put(`/user/${id}/roles`, payload).then((res) => res);

export const generateTwoFaCode = async (payload, token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  return api.post('/user/2fa/generate-qr', payload).then((res) => res.data);
};

export const enableTwoFaWithSpecialToken = async (payload) => {
  return api.post('/user/2fa/enable', payload).then((res) => res.data);
};

export const changeTwoFaStatus = async (payload, token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return api.post('/user/2fa/change-status', payload).then((res) => res.data);
};

export const removeTwoFa = async (payload) =>
  api.patch('/user/2fa/remove', payload).then((res) => res.data);

// ------------- CUSTOMERS -------------

export const getCustomers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/merchant/customers${filter}`);
};

export const exportCustomers = async (queries) => {
  const filter = queries ? `?${queries}` : '';
  return api.get(`/merchant/customers/export/csv${filter}`);
};

export const getCustomerData = async (id, signal) =>
  api.get(`/merchant/customers/${id}`, { signal }).then((res) => res.data);

export const updateCustomerData = async (id, payload) =>
  api.patch(`/merchant/customers/${id}`, payload).then((res) => res.data);

export const listCustomerDocuments = async (params, signal) =>
  api
    .get(`/merchant/customers/documents?${params}`, { signal })
    .then((res) => res);

export const exportCustomerDocumentList = async (params, signal) =>
  api
    .get(`/merchant/customers/documents/export/csv?${params}`, { signal })
    .then((res) => res);

export const getCustomerDocument = async (docId, signal) =>
  api
    .get(`/merchant/customers/documents/${docId}`, { signal })
    .then((res) => res.data);

export const uploadCustomerDocument = async (customerId, payload) =>
  api
    .post(`/merchant/customers/documents/${customerId}`, payload)
    .then((res) => res.data);

export const approveDocumentLevelThree = async (payload) =>
  api
    .put('/merchant/customers/limits/level-3/approve', payload)
    .then((res) => res.data);

export const refuseDocumentLevelThree = async (payload) =>
  api
    .put('/merchant/customers/limits/level-3/refuse', payload)
    .then((res) => res.data);

export const removePepStatus = async (userId) =>
  api.patch(`/merchant/customers/${userId}/remove-pep`).then((res) => res.data);

export const manualKycRequest = async (payload) =>
  api
    .post('/merchant/customers/documents/kyc-manual/request', payload)
    .then((res) => res.data);

// ------------- MERCHANTS -------------

export const createMerchant = async (data) =>
  api.post('/merchant', data).then((res) => res.data.data);

export const getMerchants = async (params, signal) =>
  api.get(`/merchant?${params}`, { signal });

export const exportMerchants = async (params, signal) =>
  api.get(`/merchant/export/csv?${params}`, { signal }).then((res) => res);

export const listMerchantsDropdown = async (signal) =>
  api.get('/merchant/dropdown', { signal }).then((res) => res.data);

export const getSummary = async (params) =>
  api.get('/merchant/transactions/summary', { params }).then((res) => res.data);

export const getMerchantById = async (id, signal) =>
  api.get(`/merchant/${id}`, { signal }).then((res) => res);

export const deleteMerchantById = async (merchantId, signal) =>
  api.delete(`/merchant/${merchantId}`, {}, { signal }).then((res) => res);

export const updateMerchant = async (id, payload, signal) =>
  api.patch(`/merchant/${id}`, payload, { signal }).then((res) => res);

export const getCurrencies = async (signal) =>
  api.get('/merchant/currencies?active=1', {}, { signal }).then((res) => res);

export const getOAuthClient = async (signal) =>
  api.get('/merchant/oauth/get', { signal }).then((res) => res.data);

export const generateSecretKey = async () =>
  api.patch('/merchant/oauth/generate-secret').then((res) => res.data);

// ------------- MERCHANTS / WALLETS-------------

export const getMerchantWallets = async (signal) =>
  api.get('/merchant/wallets', { signal }).then((res) => res.data);

export const getWalletByMerchantId = async (id, signal) =>
  api
    .get(`/merchant/wallets?merchant_id[]=${id}`, { signal })
    .then((res) => res);

export const setMerchantDefaultWallet = async (walletId) =>
  api.patch(`/merchant/wallets/${walletId}/default`).then((res) => res);

export const createMerchantWallet = async (payload) =>
  api.post('/merchant/wallets', payload).then((res) => res);

export const createMultipleMerchantWallets = async (payload) =>
  api.post('/merchant/wallets/many', payload).then((res) => res.data);

export const updateMerchantWallet = async (walletId, payload) =>
  api.patch(`/merchant/wallets/${walletId}`, payload).then((res) => res);

export const deleteMerchantWallet = async (walletId) =>
  api.delete(`/merchant/wallets/${walletId}`).then((res) => res);

export const updateKycValidationStatus = async (userId, type, payload) =>
  api
    .patch(`/merchant/customers/${userId}/kyc/${type}/update-status`, payload)
    .then((res) => res.data);

// ------------- TRANSACTIONS -------------

export const getPixAndGoTransaction = async (params, signal) =>
  await api
    .get(`/merchant/backoffice/pixgo?${params}`, { signal })
    .then((res) => {
      return res;
    });

export const exportPixAndGoTransaction = async (params, signal) =>
  await api
    .get(`/merchant/backoffice/pixgo/export?${params}`, { signal })
    .then((res) => {
      return res;
    });

export const getTransactions = async (params, signal) =>
  api.get(`/merchant/transactions?${params}`, { signal }).then((res) => {
    return res;
  });

export const getIssues = async (params, signal) =>
  api.get(`/merchant/transactions/issues?${params}`, { signal }).then((res) => {
    return res;
  });

export const getIssuesCount = (signal) =>
  api
    .get('/merchant/transactions/issues/open', { signal })
    .then((res) => res.data.data.open);

export const createIssue = async (payload) => {
  return api
    .post('/merchant/transactions/issues', payload)
    .then((res) => res.data);
};

export const deleteIssue = async (id) => {
  return api
    .delete(`/merchant/transactions/issues/${id}`)
    .then((res) => res.data);
};

export const updateIssue = async (id, payload) => {
  return api
    .patch(`/merchant/transactions/issues/${id}`, payload)
    .then((res) => res.data);
};

export const getIssueById = async (id) => {
  return api.get(`/merchant/transactions/issues/${id}`).then((res) => res.data);
};

export const exportTransactions = async (params, signal) =>
  api
    .get(`/merchant/transactions/export/csv?${params}`, { signal })
    .then((res) => {
      return res;
    });

export const exportTransactionOffline = async (params) =>
  api.get(`/merchant/transactions/export-offline?${params}`).then((res) => {
    return res.data;
  });

export const listTransactionCancelReasons = async (signal) =>
  api
    .get('/merchant/transactions/cancel-reasons', { signal })
    .then((res) => res.data);

export const getTransactionById = async (transactionId, params) =>
  api.get(`/merchant/transactions/${transactionId}`, { params });

export const getTransactionByUuid = async (uuid) => {
  return api.get(`merchant/backoffice/pixgo/${uuid}`).then((res) => res.data);
};

export const approveTransactionById = async (transactionId) =>
  api
    .post(`/merchant/transactions/${transactionId}/approve`)
    .then((res) => res.data);

export const refuseTransactionById = async (transactionId, payload) =>
  api
    .post(`/merchant/transactions/${transactionId}/refuse`, payload)
    .then((res) => res.data);

export const getAmountTransactions = async (params, signal) =>
  api
    .get(`/report/transactions/amount?${params}`, { signal })
    .then((res) => res.data);

export const getAmountFeeProfits = async (params, signal) =>
  api
    .get(
      `/report/transactions/amount?type=both&merchant_id=0&sum_columns[]=sum_fee_profit&sum_columns[]=sum_exchange_profit&${params}`,
      { signal }
    )
    .then((res) => res.data);

export const getPendingTransactions = async (params) =>
  api.get(`/merchant/transactions/reviews?${params}`).then((res) => res);

export const exportPendingTransactions = async (params) =>
  api
    .get(`/merchant/transactions/reviews/export/csv?${params}`)
    .then((res) => res);

export const getPendingReviewTransactionSummary = async (params, signal) =>
  api
    .get(`/merchant/transactions/reviews/summary?${params}`, { signal })
    .then((res) => res.data);

export const getPendingWithdrawalsSummary = async (signal) =>
  api
    .get('/merchant/transactions/pending-withdrawals/summary', { signal })
    .then((res) => res.data);

export const manualUpdateTransaction = async (transactionId, action, payload) =>
  api
    .patch(`/merchant/transactions/${transactionId}/${action}`, payload)
    .then((res) => res.data);

// ------------- ACAM REPORTS -------------

export const getAcamReportById = async (id, signal) =>
  api.get(`/merchant/acam-reports/${id}`, { signal }).then((res) => res.data);

export const getAcamReportTransactions = async (id, params, signal) =>
  api
    .get(`/merchant/acam-reports/${id}/transactions`, { params, signal })
    .then((res) => res.data);

export const getAcamReports = async (params, signal) =>
  api.get(`/merchant/acam-reports?${params}`, { signal }).then((res) => res);

export const exportAcamReports = async (params, signal) =>
  api
    .get(`/merchant/acam-reports/export/csv?${params}`, { signal })
    .then((res) => res);

export const getAvailableReportTransactions = async (params, signal) =>
  api
    .get(`/merchant/acam-reports/transactions?${params}`, { signal })
    .then((res) => res.data);

export const createAcamReport = async (payload, signal) =>
  api
    .post('/merchant/acam-reports', payload, { signal })
    .then((res) => res.data);

export const updateAcamReportData = async (id, payload, signal) =>
  api
    .patch(`/merchant/acam-reports/${id}`, payload, { signal })
    .then((res) => res);

export const updateAcamReportTransactions = async (id, payload) =>
  api
    .post(`/merchant/acam-reports/${id}/transactions`, payload)
    .then((res) => res.data);

export const deleteAcamReportTransactions = async (id) =>
  api
    .delete(`/merchant/acam-reports/${id}/transactions`)
    .then((res) => res.data);

export const deleteAcamReportByid = async (id) =>
  api.delete(`/merchant/acam-reports/${id}`).then((res) => res.data);

export const completeAcamReportById = async (id, payload) =>
  api
    .patch(`/merchant/acam-reports/${id}/complete`, payload)
    .then((res) => res.data);

export const calculateAcamReport = async (Id) =>
  api.patch(`/merchant/acam-reports/${Id}/calculate`).then((res) => res.data);

export const exportAcamReportById = async (id) =>
  api.get(`/merchant/acam-reports/${id}/export`).then((res) => res.data);

// ------------- REPORTS -------------

export const getSummaryTransactionsByDate = async (params, signal) =>
  api
    .get('/report/transactions/daily/summary/by-date', { params, signal })
    .then((res) => res);

export const updateSummaryTransactionsByDate = async (payload) =>
  api.put('/report/transactions/daily', payload).then((res) => res);

export const getSummaryTransactionsByBank = async (params, signal) =>
  api
    .get('/report/transactions/daily-brt', { params, signal })
    .then((res) => res);

export const updateSummaryTransactionsByBank = async (payload) =>
  api.put('/report/transactions/daily-brt', payload).then((res) => res);

export const exportSummaryTransactionsByDate = async (params, signal) =>
  api
    .get(`/report/transactions/daily/summary/by-date/export/csv?${params}`, {
      signal,
    })
    .then((res) => res);

export const exportSummaryTransactionsByBank = async (params, signal) =>
  api
    .get(
      `/report/transactions/daily-brt/summary/by-date/export/csv?${params}`,
      {
        signal,
      }
    )
    .then((res) => res);

export const getSummaryTransactionsByCustomer = async (params, signal) =>
  api
    .get(`/report/transactions/summary/by-customer?${params}`, { signal })
    .then((res) => res);

export const exportSummaryTransactionsByCustomer = async (params, signal) =>
  api
    .get(`/report/transactions/summary/by-customer/export/csv?${params}`, {
      signal,
    })
    .then((res) => res);

export const fetchGuestTokenFromBackend = async (token) => {
  return await api
    .get(`/report/superset-token/${token}`)
    .then((res) => res.data.data.guest_token.token)
    .catch((error) => error);
};

export const getMonthlyReport = async (signal) =>
  api.get('/report/monthly-reports', { signal }).then((res) => res.data);

export const downloadMonthlyReport = async () =>
  api.get('/report/monthly-reports/download').then((res) => res);

export const downloadMonthlyReportById = async (reportId) =>
  api
    .get(`/report/monthly-reports/${reportId}/download`)
    .then((res) => res.data);

export const getMonthlyReportByMerchantId = async (params, signal) => {
  const merchantId = params.get('merchant_id[]');
  params.delete('merchant_id[]');
  return api
    .get(`/report/monthly-reports/${merchantId}?${params}`, { signal })
    .then((res) => res.data);
};
export const adminExportMonthlyReportByMerchantId = async (params, signal) => {
  const merchantId = params.get('merchant_id[]');
  params.delete('merchant_id[]');
  return api.get(`report/monthly-reports/${merchantId}/export/csv`, { signal });
};
export const merchantExportMonthlyReport = async (params, signal) =>
  api.get(`/report/monthly-reports/export/csv`, { signal });

export const countTransactionByBank = async (params, signal) =>
  api
    .get('/report/transactions/count', { params, signal })
    .then((res) => res.data);

export const listAudits = async (params, signal) =>
  api.get('/report/audits', { params, signal }).then((res) => res.data);

// ------------- BANKS -------------

export const getBankList = async (params, signal) =>
  api.get(`/banks?${params}`, { signal });

export const exportBankList = async (params, signal) =>
  api.get(`/banks/export/csv?${params}`, { signal });

export const getBank = async (bankId, signal) =>
  api.get(`/banks/${bankId}`, { signal }).then((res) => res);

export const createBank = async (payload) =>
  api.post('/banks', payload).then((res) => res.data);

export const updateBank = async (bankId, payload) =>
  api.put(`/banks/${bankId}`, payload).then((res) => res);

export const deleteBankById = async (bankId) =>
  api.delete(`/banks/${bankId}`).then((res) => res);

export const listPaymentMethodsByBankId = async (bankId, signal) =>
  api
    .get(`/banks/bank-methods?bank_id[]=${bankId}`, { signal })
    .then((res) => res.data);

export const createBankMethod = async (payload) =>
  api.post('/banks/bank-methods', payload).then((res) => res);

export const updateBankMethod = async (methodId, payload) =>
  api.put(`/banks/bank-methods/${methodId}`, payload).then((res) => res);

export const deleteBankMethod = async (methodId) =>
  api.delete(`/banks/bank-methods/${methodId}`).then((res) => res.data);

export const getPaymentMethods = async (signal) =>
  api.get('/banks/methods?active=1', { signal }).then((res) => res);

export const simulateBankCallbackTransaction = async (payload) =>
  api
    .patch('/merchant/transactions/simulate/status', payload)
    .then((res) => res.data);

export const listCustomerBanks = async (params, signal) =>
  api
    .get(`/banks/customer-banks?${params}`, { signal })
    .then((res) => res.data);

export const createCustomerBanks = async (payload) =>
  api.post('/banks/customer-banks', payload).then((res) => res.data);

export const updateCustomerBankById = async (id, payload) =>
  api.patch(`/banks/customer-banks/${id}`, payload).then((res) => res.data);

export const deleteCustomerBankById = async (id) =>
  api.delete(`/banks/customer-banks/${id}`).then((res) => res.data);

export const listBanksDropdown = async (signal) =>
  api.get('/banks/dropdown', { signal }).then((res) => res.data);

export const getSummaryByMonth = async (params, signal) =>
  api.get('/report/transactions/daily-brt/summary/by-month', {
    params,
    signal,
  });

// ------------- NOTES -------------

export const getNoteByEntity = async (entity, entityId) =>
  api
    .get(
      `/misc/notes?entity_slug[]=${entity}&entity_id[]=${entityId}&per_page=50`
    )
    .then((res) => res.data);

export const createNote = async (payload) =>
  api.post('/misc/notes', payload).then((res) => res.data);

export const updateNoteById = async (id, payload) =>
  api.patch(`/misc/notes/${id}`, payload).then((res) => res.data);

export const deleteNoteById = async (id) =>
  api.delete(`/misc/notes/${id}`).then((res) => res.data);

// ------------- NOTIFY -------------

export const listNotifications = async (params, signal) =>
  api.get(`/notify/notifications?${params}`, { signal }).then((res) => res);

export const exportNotificationList = async (params, signal) =>
  api
    .get(`/notify/notifications/export/csv?${params}`, { signal })
    .then((res) => res);
