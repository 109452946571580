import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import LogoHorizontal from 'assets/images/logo-horizontal.svg';
import FormField from 'components/atoms/FormFields/FormField';
import AppLogoContent from 'components/organisms/AppLogoContent';
import useErrorHandler from 'hooks/useErrorHandler';
import { updatePasswordWithToken } from 'services/api';
import IntlMessages from 'util/IntlMessages';
import { PASSWORD_RECOVERY_SCHEMA } from 'util/yupSchemas';

function PasswordRecovery() {
  const intl = useIntl();
  const { token } = useParams();

  const [isFetching, setIsFetching] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const { setErrors, extractErrorSlugs } = useErrorHandler();

  const { formState, handleSubmit, register } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { new_password: '', new_password_confirmation: '' },
    resolver: yupResolver(PASSWORD_RECOVERY_SCHEMA),
  });

  const resetPassword = async (values) => {
    try {
      setIsFetching(true);

      const payload = {
        ...values,
        token,
      };
      await updatePasswordWithToken(payload);

      setPasswordUpdated(true);
    } catch (err) {
      setErrors(extractErrorSlugs(err?.response));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="app-login--container">
      <div className="app-login--wrapper">
        <AppLogoContent />

        <div className="app-login--content">
          <div className="app-login--form-wrapper">
            <form onSubmit={handleSubmit(resetPassword)}>
              <div className="app-login--logo-wrapper">
                <h1>
                  <img src={LogoHorizontal} alt="vpag" />
                </h1>
                <span className="text-vpag-primary">Gateway</span>
              </div>
              {!passwordUpdated ? (
                <>
                  <span>
                    <IntlMessages id="app.passwordRecovery.description" />
                  </span>
                  <div className="input-container flex-grow-1 pb-5">
                    <FormField
                      name="new_password"
                      type="password"
                      errors={formState.errors}
                      size="full-size"
                      register={register}
                      labelClassName="text-center"
                      label={intl.formatMessage({
                        id: 'app.passwordRecovery.form.password',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'app.passwordRecovery.form.password.placeholder',
                      })}
                    />
                    <FormField
                      name="new_password_confirmation"
                      type="password"
                      errors={formState.errors}
                      size="full-size"
                      register={register}
                      labelClassName="text-center"
                      label={intl.formatMessage({
                        id: 'app.passwordRecovery.form.passwordConfirmation',
                      })}
                      placeholder={intl.formatMessage({
                        id:
                          'app.passwordRecovery.form.passwordConfirmation.placeholder',
                      })}
                    />
                  </div>

                  <div className="app-login--btn-wrapper flex-column">
                    <button
                      type="submit"
                      disabled={isFetching}
                      className="app-login--submit-btn"
                    >
                      <IntlMessages id="app.passwordRecovery.form.submit" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <i className="zmdi zmdi-hc-fw zmdi-check-circle jr-fs-xlxlxl text-vpag-primary" />
                    </div>
                    <span className="text-center">
                      <IntlMessages id="app.passwordRecovery.sucessMessage" />
                    </span>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <Link to="/signin" className="form-button">
                      <IntlMessages id="app.forgotPassword.return" />
                    </Link>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      {isFetching && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default PasswordRecovery;
