import React from 'react';
import { Toaster } from 'react-hot-toast';

import { colors } from 'util/colors';


function ToasterContainer() {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        success: {
          style: {
            background: colors.gray[800],
            color: colors.white,
          },
        },
        error: {
          style: {
            background: colors.gray[800],
            color: colors.white,
          },
        },
      }}
    />
  );
}

export default ToasterContainer;
