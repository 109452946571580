import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import IntlMessages from 'util/IntlMessages';
function useErrorHandler() {
  const [errorList, setErrorList] = useState([]);

  const setErrors = (newErrors) => {
    if (Array.isArray(newErrors)) {
      setErrorList((state) => [...state, ...newErrors]);
    } else {
      setErrorList((state) => [...state, newErrors]);
    }
  };

  const resetErrors = () => {
    setErrorList([]);
  };

  useEffect(() => {
    errorList.forEach((err) => {
      toast.error(err, { id: err });
    });
  }, [errorList]);

  const extractErrorSlugs = (response) => {
    if (response?.data?.message === 'validation-error') {
      return Object.entries(response.data.data).map(([key, slug]) => (
        <IntlMessages id={`error.${slug}`} defaultMessage={`${key}: ${slug}`} />
      ));
    }
    return [
      <IntlMessages
        id={`error.${response?.data?.message}`}
        defaultMessage={<IntlMessages id="error.unspecific" />}
      />,
    ];
  };
  return { errors: errorList, setErrors, extractErrorSlugs, resetErrors };
}
export default useErrorHandler;
