import React from 'react';
import { useState } from 'react';

function PasswordInput({
  disableAutoComplete,
  disableButton,
  error,
  className,
  name,
  size,
  register,
  ...props
}) {
  const [isShowingPassword, setIsShowingPassword] = useState(false);
  return (
    <div className={`input-password form-field mb-0 ${size}`}>
      <input
        className={`form-field__input letter-spacing-${
          isShowingPassword ? 'base' : 'lg'
        } ${className} ${error ? 'error-input-borders' : ''} `}
        type={isShowingPassword ? 'text' : 'password'}
        {...(register ? { ...register(name) } : {})}
        {...props}
      />
      {!disableButton && (
        <span
          class={`zmdi zmdi-eye${isShowingPassword ? '-off' : ''}`}
          onClick={() => setIsShowingPassword((state) => !state)}
        />
      )}
    </div>
  );
}

export default PasswordInput;
