import React from 'react';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from 'actions/Auth';

import { SIGNIN_USER, SIGNOUT_USER } from 'constants/ActionTypes';
import AppLocale from 'lngProvider';
import { signIn } from 'services/api';
import IntlMessages from 'util/IntlMessages';

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await signIn({
    email,
    password,
    password_confirmation: password,
  });

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  const navLenguage = navigator.language.slice(0, 2);
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password
    );

    if (!signInUser?.message) {
      yield put(
        showAuthMessage(
          <IntlMessages
            id={`error.signIn.${signInUser?.data?.message}`}
            defaultMessage={<IntlMessages id="error.signIn.unspecific" />}
          />
        )
      );
    } else {
      localStorage.setItem('user_data', JSON.stringify(signInUser.data));
      yield put(userSignInSuccess(signInUser.data));
    }
  } catch (error) {
    yield put(
      showAuthMessage(AppLocale[navLenguage].messages['extraPages.500Msg'])
    );
  }
}

function* signOut() {
  try {
    localStorage.removeItem('user_data');
    localStorage.removeItem('banks');
    localStorage.removeItem('merchants');
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signOutUser)]);
}
