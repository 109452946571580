import React, { useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import LogoHorizontal from 'assets/images/logo-horizontal.svg';
import AppLogoContent from 'components/organisms/AppLogoContent';
import FormField from 'components/atoms/FormFields/FormField';
import ReturnButton from 'components/atoms/ReturnButton';
import useErrorHandler from 'hooks/useErrorHandler';
import { requestRecoverPassword } from 'services/api';
import IntlMessages from 'util/IntlMessages';
import { FORGOT_PASSWORD_SCHEMA } from 'util/yupSchemas';

function ForgotPassword() {
  const intl = useIntl();
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { setErrors, extractErrorSlugs } = useErrorHandler();

  const { formState, handleSubmit, register } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { email: '' },
    resolver: yupResolver(FORGOT_PASSWORD_SCHEMA),
  });

  const handleRequest = async (values) => {
    try {
      setIsFetching(true);
      await requestRecoverPassword(values);

      setEmailSent(true);
    } catch (err) {
      setErrors(extractErrorSlugs(err?.response));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <div className="app-login--container">
      <div className="app-login--wrapper">
        <AppLogoContent />
        <div className="app-login--content">
          <ReturnButton path="/signin" />
          <div className="app-login--form-wrapper">
            <form onSubmit={handleSubmit(handleRequest)}>
              <div className="app-login--logo-wrapper">
                <h1>
                  <img src={LogoHorizontal} alt="vpag" />
                </h1>
                <span className="text-vpag-primary">Flash</span>
              </div>
              {!emailSent ? (
                <>
                  <span>
                    <IntlMessages id="app.forgotPassword.description" />
                  </span>
                  <div className="input-container flex-grow-1 pb-5">
                    <FormField
                      name="email"
                      type="email"
                      errors={formState.errors}
                      size="full-size"
                      register={register}
                      labelClassName="text-center"
                      placeholder={intl.formatMessage({
                        id: 'app.forgotPassword.form.email.placeholder',
                      })}
                      label={intl.formatMessage({
                        id: 'app.forgotPassword.form.email',
                      })}
                    />
                  </div>
                  <div className="app-login--btn-wrapper">
                    <button
                      type="submit"
                      disabled={isFetching}
                      className="app-login--submit-btn"
                    >
                      <IntlMessages id="app.forgotPassword.form.submit" />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <i className="zmdi zmdi-hc-fw zmdi-email jr-fs-xlxlxl text-vpag-primary" />
                    </div>
                    <span className="text-center">
                      <IntlMessages id="app.forgotPassword.sucessMessage" />
                    </span>
                  </div>
                  <div className="w-100 d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={() => history.push('/signin')}
                      className="app-login--submit-btn"
                    >
                      <IntlMessages id="app.forgotPassword.return" />
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      {isFetching && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
