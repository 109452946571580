import React from 'react';
import * as yup from 'yup';

import { SLUG_REGEX, URL_REGEX } from 'constants/Misc';
import { default as I } from './IntlMessages';

const NAME_REQUIRED = <I id="form.error.name.required" />;
const EMAIL_REQUIRED = <I id="form.error.email.required" />;
const EMAIL_INVALID = <I id="form.error.email.invalid" />;
const SLUG_REQUIRED = <I id="form.error.slug.required" />;
const SLUG_INVALID = <I id="form.error.slug.invalid" />;
const WALLET_REQUIRED = <I id="form.error.wallet.required" />;
const CURRENCY_REQUIRED = <I id="form.error.currency.required" />;
const ROLE_REQUIRED = <I id="form.error.role.required" />;
const PASSWORD_REQUIRED = <I id="form.error.password.required" />;
const PASSWORD_INVALID = <I id="form.error.password.invalid" />;
const PASS_CONF_REQ = <I id="form.error.password_confirmation.required" />;
const PASS_CONF_INVALID = <I id="form.error.password_confirmation.invalid" />;
const NEW_PASSWORD_REQUIRED = <I id="form.error.new_password.required" />;
const CLIENT_ID_REQUIRED = <I id="form.error.client_id.required" />;
const CLIENT_SECRET_REQUIRED = <I id="form.error.client_secret.required" />;
const BASE_URL_REQUIRED = <I id="form.error.base_url.required" />;
const URL_INVALID = <I id="form.error.url.invalid" />;
const LOGIN_PIX_PATH_REQUIRED = <I id="form.error.login_pix_path.required" />;
const CREATE_PIX_PATH_REQUIRED = <I id="form.error.create_pix_path.required" />;
const WITHDRAWAL_PATH_REQ = <I id="form.error.withdrawal_pix_path.required" />;
const PIX_KEY_REQUIRED = <I id="form.error.pix_key.required" />;
const ASS_MERCHANT_REQUIRED = <I id="form.error.associatedMerchant.required" />;
const MERCHANT_ID_REQUIRED = <I id="form.error.merchant_id.required" />;
const SPREAD_REQUIRED = <I id="form.error.spread.required" />;
const EXCHANGE_RATE_REQUIRED = <I id="form.error.exchangeRate.required" />;
const SETTLEMENT_TYPE_REQUIRED = <I id="form.error.settlement_type.required" />;
const EXCH_CURRENCY_REQUIRED = <I id="form.error.exchange_currency.required" />;
const SETTLEMENT_FEE_REQUIRED = <I id="form.error.settlement_fee.required" />;
const CONTRACT_COST_REQUIRED = <I id="form.error.contract_cost.required" />;
const BETWEEN_ZERO_ONE = <I id="form.error.value_between_zero_one" />;
const POSITIVE_VALUE = <I id="form.error.positive_value" />;
const CUSTOMER_ID_REQUIRED = <I id="form.error.customer_id.required" />;
const BANK_ID_REQUIRED = <I id="form.error.bank_id.required" />;
const INVALID_ID = <I id="form.error.invalid_id" />;
const TRANSACTION_REQUIRED = <I id="form.error.transaction_type.required" />;

const USER_BASIC_INFO_SCHEMA = {
  name: yup.string().required(NAME_REQUIRED),
  email: yup
    .string()
    .email(EMAIL_INVALID)
    .required(EMAIL_REQUIRED),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(8, PASSWORD_INVALID)
    .matches(/[a-z]+/, { message: PASSWORD_INVALID })
    .matches(/[A-Z]+/, { message: PASSWORD_INVALID })
    .matches(/\d+/, { message: PASSWORD_INVALID }),
  password_confirmation: yup
    .string()
    .required(PASS_CONF_REQ)
    .oneOf([yup.ref('password'), null], PASS_CONF_INVALID),
};

const UPDATE_PASSWORD_BASE_SCHEMA = {
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .min(8, PASSWORD_INVALID)
    .matches(/[a-z]+/, { message: PASSWORD_INVALID })
    .matches(/[A-Z]+/, { message: PASSWORD_INVALID })
    .matches(/\d+/, { message: PASSWORD_INVALID }),
  new_password_confirmation: yup
    .string()
    .required(PASS_CONF_REQ)
    .oneOf([yup.ref('new_password'), null], PASS_CONF_INVALID),
};

export const SIGN_IN_SCHEMA = yup.object({
  email: yup
    .string()
    .email(EMAIL_INVALID)
    .required(EMAIL_REQUIRED),
  password: yup.string().required(PASSWORD_REQUIRED),
});

export const FORGOT_PASSWORD_SCHEMA = yup.object({
  email: yup
    .string()
    .email(EMAIL_INVALID)
    .required(EMAIL_REQUIRED),
});

export const PASSWORD_RECOVERY_SCHEMA = yup.object(UPDATE_PASSWORD_BASE_SCHEMA);

export const PASSWORD_RESET_SCHEMA = yup.object({
  ...UPDATE_PASSWORD_BASE_SCHEMA,
  current_password: yup.string().required(PASSWORD_REQUIRED),
});

export const CREATE_MERCHANT_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  slug: yup
    .string()
    .required(SLUG_REQUIRED)
    .matches(SLUG_REGEX, {
      message: SLUG_INVALID,
    }),
  company_name: yup.string(),
  wallets: yup
    .array()
    .min(1, WALLET_REQUIRED)
    .required(WALLET_REQUIRED),
  currencies: yup
    .array()
    .min(1, CURRENCY_REQUIRED)
    .required(CURRENCY_REQUIRED),
});

export const UPDATE_MERCHANT_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  company_name: yup.string().required(NAME_REQUIRED),
  slug: yup
    .string()
    .required(SLUG_REQUIRED)
    .matches(SLUG_REGEX, {
      message: SLUG_INVALID,
    }),
});

export const MERCHANT_CURRENCIES_SCHEMA = yup.object({
  currencies: yup
    .array()
    .min(1, CURRENCY_REQUIRED)
    .required(CURRENCY_REQUIRED),
});

export const CREATE_ADMIN_USER_SCHEMA = yup.object({
  ...USER_BASIC_INFO_SCHEMA,
  roles: yup
    .array()
    .min(1, ROLE_REQUIRED)
    .required(ROLE_REQUIRED),
});

export const CREATE_MERCHANT_USER_SCHEMA = yup.object({
  ...USER_BASIC_INFO_SCHEMA,
  associatedMerchant: yup.object().required(ASS_MERCHANT_REQUIRED),
});

export const UPDATE_USER_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  email: yup
    .string()
    .email(EMAIL_INVALID)
    .required(EMAIL_REQUIRED),
});

export const CREATE_BANK_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  slug: yup
    .string()
    .required(SLUG_REQUIRED)
    .matches(SLUG_REGEX, {
      message: SLUG_INVALID,
    }),
  integration_info: yup.object().shape({
    client_id: yup.string().required(CLIENT_ID_REQUIRED),
    client_secret: yup.string().required(CLIENT_SECRET_REQUIRED),
    base_url: yup
      .string()
      .required(BASE_URL_REQUIRED)
      .matches(URL_REGEX, {
        message: URL_INVALID,
      }),
    login_pix_path: yup.string().required(LOGIN_PIX_PATH_REQUIRED),
    create_pix_path: yup.string().required(CREATE_PIX_PATH_REQUIRED),
    withdrawal_pix_path: yup.string().required(WITHDRAWAL_PATH_REQ),
    pix_key: yup.string().required(PIX_KEY_REQUIRED),
  }),
});

export const UPDATE_BANK_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  slug: yup
    .string()
    .required(SLUG_REQUIRED)
    .matches(SLUG_REGEX, {
      message: SLUG_INVALID,
    }),
});

export const CREATE_ACAM_REPORT_SCHEMA = yup.object({
  name: yup.string().required(NAME_REQUIRED),
  merchant_id: yup
    .string()
    .test((value) => value !== 'defaultValue')
    .required(MERCHANT_ID_REQUIRED),
  spread: yup
    .number()
    .nullable()
    .typeError(SPREAD_REQUIRED)
    .min(0, BETWEEN_ZERO_ONE)
    .max(1, BETWEEN_ZERO_ONE)
    .required(SPREAD_REQUIRED),
  official_exchange_rate: yup
    .number()
    .nullable()
    .typeError(EXCHANGE_RATE_REQUIRED)
    .min(0, POSITIVE_VALUE)
    .required(EXCHANGE_RATE_REQUIRED),
  settlement_type: yup
    .string()
    .test((value) => value !== 'defaultValue')
    .required(SETTLEMENT_TYPE_REQUIRED),
  exchange_currency: yup
    .string()
    .test((value) => value !== 'defaultValue')
    .required(EXCH_CURRENCY_REQUIRED),
  settlement_fee: yup
    .number()
    .nullable()
    .typeError(SETTLEMENT_FEE_REQUIRED)
    .min(0, BETWEEN_ZERO_ONE)
    .max(1, BETWEEN_ZERO_ONE)
    .required(SETTLEMENT_FEE_REQUIRED),
  contract_cost: yup
    .number()
    .nullable()
    .typeError(CONTRACT_COST_REQUIRED)
    .min(0, POSITIVE_VALUE)
    .required(CONTRACT_COST_REQUIRED),
});

export const BANK_PAYMENT_METHOD_SCHEMA = yup.object().shape({
  payment_method_id: yup.number().required(),
  payment_method_name: yup.string(),
  currency_iso: yup.string().required(),
  available_for_deposit: yup.boolean().required(),
  available_for_withdrawal: yup.boolean().required(),
  transaction_limits: yup.object({
    deposit: yup.object({
      min: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_deposit) {
            return value !== 'NaN';
          }

          return true;
        }),
      max: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_deposit) {
            return value !== 'NaN';
          }

          return true;
        }),
    }),
    withdrawal: yup.object({
      min: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_withdrawal) {
            return value !== 'NaN';
          }

          return true;
        }),
      max: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_withdrawal) {
            return value !== 'NaN';
          }

          return true;
        }),
    }),
  }),
  fees: yup.object({
    deposit: yup.object({
      fixed: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_deposit) {
            return value !== 'NaN';
          }

          return true;
        }),
      percent: yup
        .string()
        .typeError('required')
        .test('test', 'Min:0 /Max:1', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_deposit) {
            return value >= 0 && value <= 1;
          }

          return true;
        }),
    }),
    withdrawal: yup.object({
      fixed: yup
        .string()
        .typeError('required')
        .test('test', 'Required', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_withdrawal) {
            return value !== 'NaN';
          }

          return true;
        }),
      percent: yup
        .string()
        .typeError('required')
        .test('test', 'Min:0 /Max:1', (value, context) => {
          const formData = context.from[2]?.value;
          if (formData?.available_for_withdrawal) {
            return value >= 0 && value <= 1;
          }

          return true;
        }),
    }),
  }),
});

export const CREATE_CUSTOMER_BANK_SCHEMA = yup.object({
  customer_id: yup
    .number()
    .min(0, INVALID_ID)
    .nullable()
    .typeError(INVALID_ID)
    .required(CUSTOMER_ID_REQUIRED),
  bank_id: yup
    .number()
    .min(0, INVALID_ID)
    .nullable()
    .typeError(INVALID_ID)
    .required(BANK_ID_REQUIRED),
  transaction_type: yup.string().required(TRANSACTION_REQUIRED),
});

export const NO_REQUIRED_VALIDATIONS = yup.object().shape({});
