import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  dialog: {
    backgroundColor: 'transparent',
    borderRadius: '10px',
    maxWidth: '980px',
    overflow: 'hidden',
    position: 'absolute',
  },
});

function Modal({ children, closeAction, isOpen }) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={closeAction}
      open={isOpen}
      style={{ borderRadius: 15 }}
      classes={{
        paper: classes.dialog,
      }}
    >
      {children}
    </Dialog>
  );
}

export default Modal;
