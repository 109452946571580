import React from 'react';
import { Avatar } from '@material-ui/core';

import BankIcon from 'components/atoms/BankIcon';
import MerchantIcon from 'components/atoms/MerchantIcon';
import { ReactComponent as UserIcon } from 'assets/images/icons/userFull.svg';
import { getInitials } from 'util/functions';

const DEFAULT_CLASS = 'user-avatar mr-3 jr-fs-2xl jr-profile-banner--avatar';
const ICON_WIDTH = 60;

function AvatarIcon({ type, data, size }) {
  const avatarList = {
    merchant: (
      <div className="jr-profile-banner--avatar mr-3">
        <MerchantIcon
          merchant={data?.slug}
          width={size ?? ICON_WIDTH}
        />
      </div>
    ),
    acamReport: (
      <div className="jr-profile-banner--avatar mr-3">
        <MerchantIcon
          merchant={data?.slug}
          width={size ?? ICON_WIDTH}
        />
      </div>
    ),
    merchantProfile: (
      <div className="jr-profile-banner--avatar mr-3">
        <MerchantIcon
          colorful
          merchant={data?.merchants?.[0]}
          width={size ?? ICON_WIDTH}
        />
      </div>
    ),
    bank: (
      <div className="jr-profile-banner--avatar mr-3">
        <BankIcon colorful bank={data?.slug} width={size ?? ICON_WIDTH} />
      </div>
    ),
    transactionDetails: (
      <div className="jr-profile-banner--avatar mr-3">
        <MerchantIcon
          merchant={data?.merchant?.slug}
          width={size ?? ICON_WIDTH}
        />
      </div>
    ),
    approvalTransactionDetails: (
      <div className="jr-profile-banner--avatar mr-3">
        <MerchantIcon
          merchant={data?.merchant?.slug}
          width={size ?? ICON_WIDTH}
        />
      </div>
    ),
    default: (
      <div className="jr-profile-banner--avatar mr-3">
        <UserIcon width={size ?? ICON_WIDTH} height={size ?? ICON_WIDTH} />
      </div>
    ),
    customer: (
      <Avatar className={`${DEFAULT_CLASS} bg-transparent`}>
        {getInitials(data?.name ?? '')}
      </Avatar>
    ),
    sofApproval: (
      <Avatar className={`${DEFAULT_CLASS} bg-yellow-green`}>
        {getInitials(data?.customer?.name ?? '')}
      </Avatar>
    ),
    user: (
      <Avatar className={`${DEFAULT_CLASS} bg-transparent`}>
        {getInitials(data?.name ?? '')}
      </Avatar>
    ),
    reports: (
      <Avatar
        className={DEFAULT_CLASS}
        src={
          `/images/merchant/${data?.merchant?.slug ??
            'merchant'}-colorful.svg` || ''
        }
      />
    ),
  };

  return avatarList[type] ?? avatarList.default;
}

export default AvatarIcon;
