import React, { useState } from 'react';

function BankIcon({ active, bank, colorful, width, className }) {
  const [error, setError] = useState(false);

  return (
    <>
      {!error ? (
        <img
          alt={bank}
          src={
            colorful
              ? `/images/banks/${bank}-colorful.svg`
              : active
              ? `/images/banks/${bank}-active.svg`
              : `/images/bank-flat/${bank}.svg`
          }
          className={`mr-2 ${className}`}
          onError={setError}
          title={bank?.replaceAll('-', ' ')}
          width={width || 30}
        />
      ) : (
        <img
          alt="Bank"
          className="mr-2"
          src={
            active
              ? '/images/banks/default-bank-active.svg'
              : '/images/banks/default-bank.svg'
          }
          title="Bank"
          width={width || 30}
        />
      )}
    </>
  );
}

export default BankIcon;
