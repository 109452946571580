import React from 'react';
import { useHistory } from 'react-router-dom';

function ReturnButton({ path }) {
  const history = useHistory();
  return (
    <button
      type="button"
      className="border-0 bg-transparent position-absolute"
      onClick={() => history.push(path)}
      style={{ left: 0 }}
    >
      <i className="zmdi zmdi-hc-fw zmdi-long-arrow-left jr-fs-2xl text-vpag-primary" />
    </button>
  );
}

export default ReturnButton;
