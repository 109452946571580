export const colors = {
  background: '#1d1d1b',
  danger: '#db0000',
  info: '#247fd0',
  purple: '#a111a4',
  sucess: '#00ac33',
  white: '#fff',
  yellowPrimary: '#bff736',

  yellowGreen: {
    500: '#bff736',
    600: '#7f9f17',
    700: '#5d770d',
  },

  gray: {
    100: '#e1d9cd',
    200: '#cccccc',
    600: '#6e757a',
    700: '#4b5258',
    800: '#32373a',
  },

  red: {
    400: '#ff2b2b',
    500: '#db0000',
    600: '#920000',
    700: '#670000',
  },
};

export const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha || 1})`;
};
