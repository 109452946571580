import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { randomString } from 'util/functions';

const BACKSPACE = 8;
const ONLY_NUMBERS_REGEX = /[^0-9]/g;

function CodeInputField({ code, setCode, length, codeDividerIndex }) {
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    const newCode = [...code];
    newCode[slot] = num.replace(ONLY_NUMBERS_REGEX, '');
    setCode(newCode);
    if (slot !== length - 1 && newCode[slot]) {
      inputs.current[slot + 1].focus();
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === BACKSPACE && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div
      className="d-flex align-items-center gap-xs"
      onPaste={(e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        if (!paste.match(/^[0-9]+$/)) return;
        const newCode = [...code];
        paste.split('').forEach((num, idx) => {
          if (idx < length) {
            newCode[idx] = num;
          }
        });
        setCode(newCode);

        if (newCode[length - 1]) {
          inputs.current[length - 1].focus();
        }
      }}
    >
      {code.map((num, idx) => {
        return (
          <>
            {codeDividerIndex === idx && <i class="zmdi zmdi-minus" />}
            <input
              key={() => randomString()}
              type="text"
              inputMode="numeric"
              className="code-input-field"
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              onChange={(e) => processInput(e, idx)}
              onFocus={({ target }) => target.select()}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
            />
          </>
        );
      })}
    </div>
  );
}

CodeInputField.propTypes = {
  code: PropTypes.arrayOf(PropTypes.number).isRequired,
  codeDividerIndex: PropTypes.number,
  length: PropTypes.number,
  setCode: PropTypes.func.isRequired,
};

export default CodeInputField;
