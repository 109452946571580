const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Español',
    icon: 'es',
  },
  {
    languageId: 'portugues',
    locale: 'pt',
    name: 'Português',
    icon: 'br',
  },
];
export default languageData;
