import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from 'util/colors';

const useStyles = makeStyles(() => ({
  transform: 'translateZ(0)',
  backdrop: {
    background: 'rgba(0, 0, 0, 0.80)',
    color: colors.yellowGreen[500],
    zIndex: 1250,
  },
}));

function Overlay({ isOpen }) {
  const classes = useStyles();

  return <Backdrop className={classes.backdrop} open={isOpen} />;
}

export default Overlay;
