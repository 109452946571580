import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CircularProgress from '@material-ui/core/CircularProgress';

import { userSignInSuccess } from 'actions/Auth';
import LogoHorizontal from 'assets/images/logo-horizontal.svg';
import FormField from 'components/atoms/FormFields/FormField';
import AppLogoContent from 'components/organisms/AppLogoContent';
import TwoFaRequired from 'components/organisms/Modal/TwoFaRequired';
import Modal from 'components/organisms/Modal';
import CustomToast from 'components/organisms/Modal/CustomToast';
import TwoFaValidator from 'components/organisms/Modal/TwoFaValidator';
import {
  changeTwoFaStatus,
  enableTwoFaWithSpecialToken,
  generateTwoFaCode,
  signIn,
} from 'services/api';
import IntlMessages from 'util/IntlMessages';
import { SIGN_IN_SCHEMA } from 'util/yupSchemas';
import useErrorHandler from 'hooks/useErrorHandler';

function SignIn() {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [showTwoFaValidator, setShowTwoFaValidator] = useState(false);
  const [showExpiredSession, setShowExpiredSession] = useState(false);
  const [request2Fa, setRequest2Fa] = useState(false);
  const [userData, setUserData] = useState({});
  const [twoFaData, setTwoFaData] = useState({});
  const { setErrors, extractErrorSlugs } = useErrorHandler();
  const { formState, handleSubmit, register, setValue } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(SIGN_IN_SCHEMA),
  });

  const generateQrCode = async ({ password }, token) => {
    try {
      setIsFetching(true);
      const res = await generateTwoFaCode({ password }, token);
      const user = res.data.user;

      setTwoFaData({ secret: user?.secret, qrCode: user?.qr_url });
    } catch (err) {
      setErrors(extractErrorSlugs(err?.response));
    } finally {
      setIsFetching(false);
    }
  };

  const signInErrorAlert = (errorSlug) => {
    toast.error(
      <IntlMessages
        id={`error.signIn.${errorSlug}`}
        defaultMessage={<IntlMessages id="error.signIn.unspecific" />}
      />,
      {
        id: 'login-error-message',
        position: 'top-center',
      }
    );
  };

  const signInErrorHandler = (errorSlug) => {
    switch (errorSlug) {
      case 'user-has-2fa':
        setShowTwoFaValidator(true);
        break;
      case 'invalid-2fa':
        setShowTwoFaValidator(true);
        signInErrorAlert(errorSlug);
        break;
      default:
        signInErrorAlert(errorSlug);
    }
  };

  const handleSignIn = async (formValues) => {
    try {
      setIsFetching(true);
      showTwoFaValidator && setShowTwoFaValidator(false);
      const user = await signIn(formValues);
      if (user?.data?.require_2fa) {
        handleRequired2Fa(user.data);
      } else {
        await handleSignInData(user, formValues);
      }
    } catch (err) {
      signInErrorHandler(err?.response?.data?.message);
    } finally {
      setIsFetching(false);
    }
  };

  const setQrCode = async (user, formValues) => {
    await generateQrCode(formValues, user?.data?.token);
    setRequest2Fa(true);
  };

  const setSignInData = (user) => {
    localStorage.setItem('user_data', JSON.stringify(user.data));
    dispatch(userSignInSuccess(user.data));
    history.push('/app/dashboard');
  };

  const handleRequired2Fa = async (user) => {
    if (!user.has_2fa) {
      setUserData(user);
      setTwoFaData({ secret: user?.secret, qrCode: user?.qr_url });
      setRequest2Fa(true);
    }
  };

  const handleSignInData = async (user, formValues) => {
    if (!user?.data?.has_2fa && user?.data?.type === 'administrator') {
      setUserData(user?.data);
      await setQrCode(user, formValues);
    } else {
      setSignInData(user);
    }
  };

  const activeTwoFa = async (code) => {
    try {
      setIsFetching(true);
      const payload = {
        code_2fa: code,
        status: true,
      };
      let user = {};

      if (userData.token_2fa) {
        user = await enableTwoFaWithSpecialToken({
          token_2fa: userData?.token_2fa,
          code_2fa: code,
        }).then((res) => {
          setRequest2Fa(false);
          return res.data;
        });
      } else {
        await changeTwoFaStatus(payload, userData?.token);
        user = userData;
      }
      toast.success(
        <IntlMessages id="component.passwordMananger.2fa.successMessage" />,
        {
          id: 'success-message',
        }
      );
      const data = { ...user, has_2fa: true };
      localStorage.setItem('user_data', JSON.stringify(data));
      dispatch(userSignInSuccess(data));
      history.push('/app/dashboard');
    } catch (err) {
      toast.error(<IntlMessages id="error.2faSetup.unspecific" />, {
        id: '2fa-setup-unspecific',
        position: 'top-center',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const skip2FaSetup = () => {
    localStorage.setItem('user_data', JSON.stringify(userData));
    dispatch(userSignInSuccess(userData));
    history.push('/app/dashboard');
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('show_expired_session'))) {
      setShowExpiredSession(true);
      localStorage.setItem('show_expired_session', false);
    }

    return () => {
      setShowExpiredSession(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-login--container">
      <CustomToast
        isOpen={showExpiredSession}
        close={() => setShowExpiredSession(false)}
        type="sessionExpired"
      />
      <div className="app-login--wrapper">
        <AppLogoContent />

        <div className="app-login--content">
          <div className="app-login--form-wrapper">
            <form onSubmit={handleSubmit(handleSignIn)}>
              <div className="app-login--logo-wrapper">
                <h1>
                  <img src={LogoHorizontal} alt="vpag" />
                </h1>
                <span className="text-vpag-primary">Flash</span>
              </div>
              <div className="input-container">
                <FormField
                  name="email"
                  type="email"
                  errors={formState.errors}
                  size="full-size"
                  register={register}
                  placeholder={intl.formatMessage({
                    id: 'app.login.form.user',
                  })}
                />
                <FormField
                  name="password"
                  type="password"
                  errors={formState.errors}
                  register={register}
                  size="full-size"
                  placeholder={intl.formatMessage({
                    id: 'app.login.form.password',
                  })}
                />
                <Link to="/forgot-password">
                  <IntlMessages id="app.login.forgotPassword" />
                </Link>
              </div>
              <div className="app-login--btn-wrapper">
                <button
                  type="submit"
                  disabled={isFetching}
                  className="app-login--submit-btn"
                >
                  <IntlMessages id="appModule.enter" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isFetching && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      <Modal
        isOpen={showTwoFaValidator}
        closeAction={() => {
          setShowTwoFaValidator(false);
        }}
      >
        <TwoFaValidator
          handleClick={handleSubmit(handleSignIn)}
          handleChange={setValue}
          fieldName="code_2fa"
        />
      </Modal>
      <Modal
        isOpen={request2Fa}
        closeAction={() => {
          setRequest2Fa(false);
        }}
      >
        <TwoFaRequired
          handleClose={() => setRequest2Fa(false)}
          twoFaData={twoFaData}
          onSubmit={activeTwoFa}
          isFetching={isFetching}
          handleSkip={skip2FaSetup}
          hideSkip={userData.require_2fa && !userData.has_2fa}
        />
      </Modal>
    </div>
  );
}

export default SignIn;
