import React, { useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { setInitUrl } from 'actions/Auth';
import { setDarkTheme, setThemeColor } from 'actions/Setting';
import { RestrictedRoute } from 'app/routes';
import SignIn from 'app/routes/SignIn';
import ForgotPassword from 'app/routes/Password/ForgotPassword';
import PasswordRecovery from 'app/routes/Password/RecoveryPassword';
import ToasterContainer from 'components/organisms/ToasterContainer';
import AppLayout from 'components/templates/AppLayout';
import useThemeColor from 'hooks/useThemeColor';
import AppLocale from 'lngProvider';
import indigoTheme from 'themes/indigoTheme';

import 'assets/vendors/style';

const App = ({ match, location, history }) => {
  const dispatch = useDispatch();
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const { getColorTheme } = useThemeColor();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(history.location.pathname));
    }
    const params = new URLSearchParams(location.search);
    if (params.has('theme-name')) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has('dark-theme')) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, history.location.pathname, location.search]);

  const isDarkTheme = darkTheme;
  let applyTheme = createTheme(indigoTheme);

  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  if (location.pathname === '/') {
    if (authUser === null) {
      return <Redirect to={'/signin'} />;
    } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
      return <Redirect to={'/app/dashboard'} />;
    } else {
      return <Redirect to={initURL} />;
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl');
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ThemeProvider theme={applyTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <div className="app-main vpag-theme">
          <ToasterContainer />
          <Switch>
            <RestrictedRoute
              path={`${match.url}app`}
              authUser={authUser}
              component={AppLayout}
            />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup">
              <Redirect to="/signin" />
            </Route>
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/recover-password/:token"
              component={PasswordRecovery}
            />
          </Switch>
        </div>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
