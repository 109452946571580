import React from 'react';
import { CircularProgress, List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';

const Navigation = ({ menuItems, permissions }) => {
  return (
    <List component='nav' disablePadding className='side-nav-menu'>
      {permissions ? (
        menuItems.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'hide':
              return null;
            default:
              return <NavMenuItem {...item} key={index} />;
          }
        })
      ) : (
        <div className='loader-view mt-5'>
          <CircularProgress />
        </div>
      )}
    </List>
  );
};

export default Navigation;
