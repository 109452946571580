import { createTheme } from '@material-ui/core/styles';

import {
  amberTheme,
  blueTheme,
  cyanTheme,
  greenTheme,
  indigoTheme,
  orangeTheme,
  pinkTheme,
  purpleTheme,
  vpagTheme,
} from 'themes';

function useThemeColor() {
  const themeCollection = {
    amber: createTheme(amberTheme),
    blue: createTheme(blueTheme),
    cyan: createTheme(cyanTheme),
    'dark-amber': createTheme(amberTheme),
    'dark-blue': createTheme(blueTheme),
    'dark-cyan': createTheme(cyanTheme),
    'dark-deep-orange': createTheme(orangeTheme),
    'dark-deep-purple': createTheme(purpleTheme),
    'dark-green': createTheme(greenTheme),
    'dark-indigo': createTheme(indigoTheme),
    'dark-pink': createTheme(pinkTheme),
    'deep-orange': createTheme(orangeTheme),
    'deep-purple': createTheme(purpleTheme),
    green: createTheme(greenTheme),
    indigo: createTheme(indigoTheme),
    pink: createTheme(pinkTheme),
    vpag: createTheme(vpagTheme),
    default: createTheme(indigoTheme),
  };

  const getColorTheme = (themeColor, applyTheme) => {
    applyTheme = themeCollection[themeColor] || themeCollection.default;
    return applyTheme;
  };

  return { getColorTheme };
}

export default useThemeColor;
