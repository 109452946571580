import { useState } from 'react';

const usePageTitle = () => {
  const [pageTitle, setPageTitle] = useState(document.title);

  const title = document.querySelector('title');

  title.addEventListener('change', () => setPageTitle(document.title));

  const target = document.querySelector('title');
  const observer = new MutationObserver(() => {
    setPageTitle(document.title);
  });

  observer.observe(target, { childList: true });

  return { pageTitle };
};

export default usePageTitle;
