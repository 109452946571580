import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CodeInputField from 'components/atoms/FormFields/CodeInputField';
import IntlMessages from 'util/IntlMessages';
import { TWO_FA_CODE_DIVIDER_IDX, TWO_FA_CODE_LENGTH } from 'constants/Misc';

function TwoFaValidator({ handleClick, handleChange, fieldName }) {
  const [code, setCode] = useState(Array(TWO_FA_CODE_LENGTH).fill(''));

  useEffect(() => {
    handleChange(fieldName, code.join('').trim(''));
  }, [handleChange, code, fieldName]);

  return (
    <form className="two-fa-modal px-5" onSubmit={(e) => e.preventDefault()}>
      <p className="m-0 font-family-bold text-vpag-primary mb-2 mt-4">
        <IntlMessages id="component.twoFaValidator.title" />
      </p>
      <p className="jr-fs-sm text-gray-light m-0 mb-3">
        <IntlMessages id="component.twoFaValidator.instructions" />
      </p>
      <CodeInputField
        length={TWO_FA_CODE_LENGTH}
        code={code}
        setCode={setCode}
        codeDividerIndex={TWO_FA_CODE_DIVIDER_IDX}
      />
      <button
        className="vpag-btn rounded my-4"
        onClick={() => handleClick(code.join(''))}
        disabled={code.join('').trim('').length < TWO_FA_CODE_LENGTH}
      >
        <IntlMessages id="app.general.actions.continue" />
      </button>
    </form>
  );
}

TwoFaValidator.propTypes = {
  fieldName: PropTypes.string,
  handleChange: PropTypes.func,
  handleClick: PropTypes.func,
}.isRequired;

export default TwoFaValidator;
