import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

function AppLogoContent() {
  return (
    <div className="app-logo-content d-flex align-items-center justify-content-center flex-direction-column">
      <Link className="logo-lg" to="/signin" title="vpag">
        <Logo className="logo-home" />
      </Link>
      <h1 className="app-logo-title">Backoffice - Flash</h1>
    </div>
  );
}

export default AppLogoContent;
