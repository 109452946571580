import axios from 'axios';
import { setup } from 'axios-cache-adapter';

import { getURLCached } from './cache';

const FORBIDDEN_HTTP_STATUS_CODE = 403;
const THREE_MINUTES = 180000;

const API_DEFAULT_CONFIG = {
  baseURL: process.env.REACT_APP_API_GATEWAY,
  cache: {
    exclude: {
      filter: (req) => getURLCached(req),
      query: false,
    },
    maxAge: THREE_MINUTES,
    methods: ['get'],
  },
};

export const api = setup(API_DEFAULT_CONFIG);

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
});

api.interceptors.request.use(
  (config) => {
    const userData = JSON.parse(localStorage.getItem('user_data'));

    if (userData?.token) {
      config.headers.Authorization = `Bearer ${userData?.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const refreshTokenApi = async () => {
  const user = JSON.parse(localStorage.getItem('user_data'));
  instance.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
  return instance.post('user/auth/refresh').then((res) => {
    return res.data.data.token;
  });
};

let refreshTokenPromise = null;

api.interceptors.response.use(
  (config) => config,
  async (err) => {
    const user = JSON.parse(localStorage.getItem('user_data'));

    if (
      err.response.status === FORBIDDEN_HTTP_STATUS_CODE &&
      user?.token &&
      err.config &&
      err.response
    ) {
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshTokenApi()
          .then((newToken) => {
            const userData = JSON.parse(localStorage.getItem('user_data'));
            userData.token = newToken;
            localStorage.setItem('user_data', JSON.stringify(userData));

            refreshTokenPromise = null;
            return newToken;
          })
          .catch((err) => {
            localStorage.removeItem('user_data');

            if (!localStorage.getItem('alertDisplayed')) {
              const myEvent = new CustomEvent('invalid_token', {
                bubbles: true,
                cancelable: true,
              });
              document.querySelector('body').dispatchEvent(myEvent);
              localStorage.setItem('alertDisplayed', true);
            }

            return Promise.reject(err);
          });
      }

      return refreshTokenPromise.then((token) => {
        err.config.headers['Authorization'] = `Bearer ${token}`;
        return axios.request(err.config);
      });
    }
    return Promise.reject(err);
  }
);

// File name interceptor
api.interceptors.response.use(
  (response) => {
    if (response?.headers?.['content-disposition']) {
      const filename = response?.headers?.['content-disposition']
        ?.split('filename=')[1]
        ?.replaceAll('"', '')
        ?.split('.')[0];

      return {
        ...response,
        filename,
      };
    }
    return response;
  },
  (err) => Promise.reject(err)
);
