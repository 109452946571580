import React from 'react';

import CustomScrollbars from 'components/molecules/CustomScrollBars';

import {notifications} from './data';
import NotificationItem from './NotificationItem';

const AppNotification = () => {
    return (
        <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
            <ul className="list-unstyled">
                {notifications.map((notification, index) => <NotificationItem key={index} notification={notification}/>)
                }
            </ul>
        </CustomScrollbars>
    )
};

export default AppNotification;

