import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Collapse,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { setDrawerType } from 'actions';
import { FIXED_DRAWER, MINI_DRAWER } from 'constants/ActionTypes';
import IntlMessages from 'util/IntlMessages';

function SideBarOptions() {
  const dispatch = useDispatch();
  const drawerType = useSelector(({ settings }) => settings.drawerType);
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleChange = ({ target }) => {
    dispatch(setDrawerType(target.value));
    localStorage.setItem('drawerType', target.value);
    toggleCollapse();
  };

  return (
    <div className="nav-collapse">
      <List component="nav" disablePadding className="side-nav-menu">
        <ListItem className="nav-collapse-btn" onClick={toggleCollapse} button>
          <span className="nav-text jr-fs-sm">
            <IntlMessages id="component.sidebar.options" />
          </span>
          {open ? (
            <IconExpandLess className="nav-arrow" />
          ) : (
            <IconExpandMore className="nav-arrow" />
          )}
        </ListItem>
        <Collapse className="nav-collapse-item" in={open} timeout="auto">
          <List>
            <RadioGroup
              className="d-block pl-2 nav-menu-option"
              aria-label="nav-style"
              name="navStyle"
              value={drawerType}
              onChange={handleChange}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={FIXED_DRAWER}
                label="Fixed"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={MINI_DRAWER}
                label="Mini"
              />
            </RadioGroup>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default SideBarOptions;
