import React, { useEffect, useState } from 'react';

const DEFAULT_SIZE = 30;

function MerchantIcon({
  active,
  className,
  colorful,
  merchant,
  selected,
  width,
}) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [merchant]);

  return (
    <>
      {!error ? (
        <img
          alt={merchant}
          src={
            colorful
              ? `/images/merchant/${merchant}-colorful.svg`
              : active
              ? `/images/merchants/${merchant}-active.svg`
              : `/images/merchants/${merchant}.svg`
          }
          className={selected ? 'selected' : '' + className}
          onError={setError}
          title={merchant?.replaceAll('-', ' ')}
          width={width ||DEFAULT_SIZE}
        />
      ) : (
        <img
          alt={merchant}
          className={selected ? 'selected' : ''}
          src={
            colorful
              ? '/images/merchants/merchant-default.svg'
              : active
              ? '/images/merchants/merchant-default-active.svg'
              : '/images/merchants/merchant-default.svg'
          }
          title={merchant}
          width={width ||DEFAULT_SIZE}
        />
      )}
    </>
  );
}

export default MerchantIcon;
