import React from 'react';

import { DEFAULT_TIMEZONE, TIMEZONE_STRINGS } from 'constants/Misc';

function TimezoneSelector() {
  const timezone = localStorage.getItem('timezone');

  const handleChange = ({ target }) => {
    localStorage.setItem('timezone', JSON.stringify(target.value));
    window.location.reload();
  };

  if (!timezone) {
    localStorage.setItem('timezone', JSON.stringify(DEFAULT_TIMEZONE));
  }

  return (
    <select
      className="timezone-selector"
      onChange={handleChange}
      defaultValue={JSON.parse(timezone) ?? DEFAULT_TIMEZONE}
    >
      {TIMEZONE_STRINGS.map(({ label, value }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </select>
  );
}

export default TimezoneSelector;
