import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';

import App from './App';
import configureStore, { history } from './store';

export const store = configureStore();

const MainApp = () => {
  const theme = createTheme({
    colors: {
      background: '#1d1d1b',
      white: '#fff',
      yellowPrimary: '#bff736',

      gray: {
        100: '#e1d9cd',
        600: '#6e757a',
        700: '#4b5258',
        800: '#32373a',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  );
};

export default MainApp;
