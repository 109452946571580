import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  FIXED_DRAWER,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
} from 'constants/ActionTypes';

import { VPAG } from 'constants/ThemeColors';
import { isValidJSON } from 'util/functions';

const storageLocale = localStorage.getItem('locale');
const storageDrawerType = localStorage.getItem('drawerType');
const localeDefault = {
  languageId: 'spanish',
  locale: 'es',
  name: 'Spanish',
  icon: 'es',
};

const languageConfig = isValidJSON(storageLocale)
  ? JSON.parse(storageLocale)
  : localeDefault;

const rltLocale = ['ar'];
const initialSettings = {
  navCollapsed: false,
  drawerType: storageDrawerType || FIXED_DRAWER,
  themeColor: VPAG,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: languageConfig || {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es',
  },
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType,
      };
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color,
      };
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale),
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL,
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload,
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload,
      };

    default:
      return state;
  }
};

export default settings;
