import React from 'react';
import PropTypes from 'prop-types';

function SelectField({ asyncList, label, name, options, register, ...rest }) {
  return (
    <select className="form-field__input" {...register(name)} {...rest}>
      {!rest.withoutPlaceholder && (
        <option className="form-field__option-placeholder" defaultChecked>
          {rest.placeholder || label}
        </option>
      )}
      {asyncList?.[name]
        ? asyncList[name]?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))
        : options?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
    </select>
  );
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  asyncList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default SelectField;
