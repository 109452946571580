import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/images/icons/warning-yellow.svg';
import IntlMessages from 'util/IntlMessages';

function ExpiredSession({ close }) {
  return (
    <form className="d-flex align-items-center gap-1">
      <div className="d-flex gap-1 align-items-center border__right--gray-light pr-4">
        <WarningIcon />
        <div className="d-flex flex-column">
          <span className="font-family-bold text-white">
            <IntlMessages id="modal.expiredSession.title" />
          </span>
        </div>
      </div>
      <span
        className="font-family-bold pointer p-3 pl-4 text-white border-left border-grey"
        onClick={close}
      >
        <IntlMessages id="app.general.actions.ok" />
      </span>
    </form>
  );
}

export default ExpiredSession;
