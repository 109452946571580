import React, { useEffect } from 'react';
import { toast, useToasterStore } from 'react-hot-toast';

import Overlay from 'components/organisms/Overlay';

import ExpiredSession from './ExpiredSession';

const ALERT_DURATION = 4000;

function CustomToast({ isOpen, close, type }) {
  const { toasts } = useToasterStore();

  const handleClose = async () => {
    toast.remove();
    close();
  };

  const timerClose = () => {
    setTimeout(() => {
      handleClose();
    }, ALERT_DURATION);
  };

  useEffect(() => {
    timerClose();
    return () => {
      clearTimeout(timerClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toastCollection = {
    sessionExpired: () => (
      <ExpiredSession timerClose={timerClose} close={handleClose} />
    ),
  };
  useEffect(() => {
    if (!toasts.length && isOpen) {
      toast(({ id }) => toastCollection[type](id), {
        className: 'bg-black py-2 px-1',
        duration: Infinity,
        position: 'top-center',
        style: {
          maxWidth: 'none',
          border: 'solid 1px white',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close]);

  return (
    <section
      onClick={() => {
        close();
        toast.remove();
      }}
    >
      <Overlay isOpen={isOpen} />
    </section>
  );
}

export default CustomToast;
