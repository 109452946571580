import React from 'react';
import PropTypes from 'prop-types';
import PasswordInput from 'components/atoms/PasswordInput';
import SelectField from '../SelectField';

function FormField({
  asyncList,
  className = '',
  errors,
  hideErrorMessage,
  label,
  labelClassName = '',
  name,
  options,
  register = () => {},
  size = '',
  type,
  value,
  ...rest
}) {
  const hasError = errors?.[name];
  const errorMessage = errors?.[name]?.message;

  const validator = (event) => {
    const digitPeriodRegExp = new RegExp(
      rest.allowNegative ? '\\d|\\.|\\-' : '\\d|\\.|\\,'
    );
    if (
      event.ctrlKey ||
      event.altKey ||
      typeof event.key !== 'string' ||
      event.key.length !== 1
    ) { return; }

    if (!digitPeriodRegExp.test(event.key)) {
      event.preventDefault();
    }
  };

  const inputCollection = {
    password: (
      <PasswordInput
        register={register}
        name={name}
        className={className}
        size={size}
        {...rest}
      />
    ),
    date: (
      <input
        className={`form-field__input ${className}`}
        type="date"
        {...rest}
        {...register(name)}
        {...(value ? { value } : {})}
      />
    ),
    text: (
      <input
        className={`form-field__input ${className}`}
        type={type}
        {...rest}
        {...register(name)}
      />
    ),
    number: (
      <input
      className={`form-field__input ${className}`}
      {...rest}
      autoComplete='new-password'
      {...register(name)}
      onKeyPress={validator}
    />
    ),
  };

  return (
    <div className={`form-field ${hasError ? 'error' : ''} ${size}`}>
      {label && (
        <label className={`form-field__label ${labelClassName}`} htmlFor={name}>
          {label}
        </label>
      )}
      {type === 'select' ? (
        <SelectField
          asyncList={asyncList}
          label={label}
          name={name}
          options={options}
          register={register}
          {...rest}
        />
      ) : (
        inputCollection[type ?? 'text'] || inputCollection.text
      )}
      {errorMessage && !hideErrorMessage && (
        <span className="form-field__error-message text-red">
          {errorMessage}
        </span>
      )}
    </div>
  );
}

FormField.propTypes = {
  asyncList: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  register: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default FormField;
