const URL_CACHED = ['/merchant/dropdown', '/banks/dropdown'];

export function getURLCached({ url }) {
  const urlMapped = URL_CACHED.map((pattern) => {
    const patternRegexp = new RegExp(pattern);
    return patternRegexp.test(url);
  });
  const shouldCached = urlMapped.includes(true);
  return !shouldCached;
}
