import React from 'react';

const Footer = () => {
  const date = new Date();
  return (
    <footer className="app-footer">
      <span className="d-inline-block">vpag &copy; {date.getFullYear()}</span>
    </footer>
  );
};
export default Footer;
