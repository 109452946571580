const A_HUNDRED_PER_CENT = 100;
const SEVENTY_NINE_YEARS = 79;
const EIGHTY_YEARS = 80;

export const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const arrayAreEqual = (arr1, arr2) => {
  return arr1.sort().join(',') === arr2.sort().join(',');
};

export const isEqualObject = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const getInitials = (string) => {
  let names = string?.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const groupBy = (array, key) => {
  return array.reduce((item, i) => {
    (item[i[key]] = item[i[key]] || []).push(i);
    return item;
  }, {});
};

export const getTotalMethodWeight = (banks, transactionType) => {
  const availableMethods = [];

  banks.forEach((bank) => {
    return bank?.payment_methods?.forEach(({ id }) =>
      availableMethods.push(id)
    );
  });

  const uniqueMethods = [...new Set(availableMethods)];
  const sumByMethod = {};

  uniqueMethods.forEach((methodId) => {
    const sum = banks.reduce((acc, bank) => {
      const associatedMethod = bank?.payment_methods?.find(
        ({ id }) => id === methodId
      );

      if (transactionType === 'withdrawal') {
        const isActiveAndAvailableForWithdrawal =
          associatedMethod?.active &&
          associatedMethod?.pivot?.available_for_withdrawal;

        if (bank?.active && isActiveAndAvailableForWithdrawal) {
          return acc + (bank?.withdrawal_percent ?? 0);
        }
      }

      if (transactionType === 'deposit') {
        const isActiveAndAvailableForDeposit =
          associatedMethod?.active &&
          associatedMethod?.pivot?.available_for_deposit;

        if (bank?.active && isActiveAndAvailableForDeposit) {
          return acc + (bank?.deposit_percent ?? 0);
        }
      }

      return acc;
    }, 0);

    sumByMethod[methodId] = sum;
  });

  return sumByMethod;
};

export const calculateMethodIncidence = (bankWeighting, weightingSum) => {
  return Math.round((bankWeighting * A_HUNDRED_PER_CENT) / weightingSum);
};

export const checkForBankWeightingChanges = (banks, weightings) => {
  let banksToUpdate = [];

  Object.keys(weightings).forEach((bank_id) => {
    const bank = banks.find(({ id }) => id === Number(bank_id));

    if (bank?.deposit_percent !== weightings[bank_id]) {
      banksToUpdate.push({
        bankId: Number(bank_id),
        percents: weightings[bank_id],
      });
    } else {
      banksToUpdate = banksToUpdate.filter(({ bankId }) => bankId !== bank_id);
    }
  });

  return banksToUpdate;
};

export const getSearchParamsAsObject = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const objParams = {};

  for (let x of searchParams.keys()) {
    objParams[x] = searchParams.getAll(x);
  }
  return objParams;
};

export const randomString = (Math.random() + 1).toString(36).substring(7);

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const paramsToObject = (entries, arrayFields) => {
  const result = {};
  const isArrayDataField = (name) => arrayFields.includes(name);
  for (const [key, value] of entries) {
    result[key] = arrayFields && isArrayDataField(key) ? [value] : value;
  }
  return result;
};

export const parseCustomerAgeFilterToExport = (filters) => {
  if (!filters?.eighty_plus) {
    return filters;
  }

  const result = filters;
  const ageFilter = result.eighty_plus;
  delete result.eighty_plus;

  if (ageFilter === 'active') {
    result.age_from = EIGHTY_YEARS;
  }

  if (ageFilter === 'inactive') {
    result.age_to = SEVENTY_NINE_YEARS;
  }

  return result;
};
