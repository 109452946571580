export default {
  palette: {
    primary: {
      light: '#bff736',
      main: '#bff736',
      dark: '#bff736',
      contrastText: '#252525',
    },
    secondary: {
      light: '#e1d9cd',
      main: '#4b5258',
      dark: '#32373a',
      contrastText: '#e1d9cd',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
};
