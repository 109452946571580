import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';

import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import Logo from 'assets/images/logo-horizontal.svg';
import LanguageSwitcher from 'components/molecules/LanguageSwitcher/index';
import TimezoneSelector from 'components/molecules/TimezoneSelector';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import usePageTitle from 'hooks/usePageTitle';

const PAGE_TITLE_PREFIX = 'Vpag Flash - ';

const Index = () => {
  const dispatch = useDispatch();
  const { pageTitle } = usePageTitle();
  const { drawerType, locale, navCollapsed } = useSelector(
    ({ settings }) => settings
  );

  const [langSwitcher, setLangSwitcher] = useState(false);
  const onLangSwitcherSelect = () => {
    setLangSwitcher(!langSwitcher);
  };

  const handleRequestClose = () => {
    setLangSwitcher(false);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang));
    localStorage.setItem('locale', JSON.stringify(lang));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-block d-xl-none'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'd-block'
    : 'd-none';

  return (
    <AppBar color="transparent" className="position-relative">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>

        <h5 className="page-title text-white">
          {pageTitle?.replace(PAGE_TITLE_PREFIX, '')}
        </h5>
        <Link
          className="app-logo d-none d-flex align-items-end flex-column text-decoration-none"
          to="/app/dashboard"
        >
          <img src={Logo} alt="vpag" title="vpag" />
          <span className="jr-fs-sm font-weight-bold text-grey text-lighten-2">
            Flash
          </span>
        </Link>
        <ul className="header-notifications list-inline ml-auto">
          <TimezoneSelector />
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu"
              isOpen={langSwitcher}
              toggle={onLangSwitcherSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <IconButton className="icon-btn">
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                </IconButton>
              </DropdownToggle>
              <DropdownMenu right className="w-50">
                <LanguageSwitcher
                  switchLanguage={onSwitchLanguage}
                  handleRequestClose={handleRequestClose}
                />
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
