import React from 'react';
import { useSelector } from 'react-redux';

import Routes from 'app/routes';
import AppLayouts from './AppLayouts';

const AppLayout = () => {
  const horizontalNavPosition = useSelector(
    ({ settings }) => settings.horizontalNavPosition
  );

  const navigationStyle = useSelector(
    ({ settings }) => settings.navigationStyle
  );

  const layoutCollection = {
    above_the_header: 'AboveHeaderNav',
    below_the_header: 'BelowHeaderNav',
    inside_the_header: 'InsideHeaderNav',
  };

  const Layout =
    AppLayouts[
      navigationStyle === 'vertical_navigation'
        ? 'Vertical'
        : layoutCollection[horizontalNavPosition ?? 'Vertical']
    ];
  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default AppLayout;
