import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './Auth';
import Common from './Common';
import Data from './Data';
import Settings from './Settings';

export default (history) =>
  combineReducers({
    auth: Auth,
    common: Common,
    data: Data,
    router: connectRouter(history),
    settings: Settings,
  });
