import {
  RESET_USER_LIST,
  SET_SELECTED_USER,
  SET_USER_LIST,
  RESET_SELECTED_USER,
  SET_USER_ADDRESS,
  SET_SELECTED_MERCHANTS,
  SET_CURRENCIES,
  SET_MERCHANTS,
} from 'constants/ActionTypes';

const initialSettings = {
  userList: null,
  selectedUser: null,
  userAddress: null,
  selectedMerchants: [],
  currencies: JSON.parse(localStorage.getItem('currencies')),
  merchants: [],
};

const data = (state = initialSettings, action) => {
  switch (action.type) {
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload || [],
      };

    case RESET_USER_LIST:
      return {
        ...state,
        userList: null,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case RESET_SELECTED_USER:
      return {
        ...state,
        selectedUser: null,
      };
    case SET_USER_ADDRESS:
      return {
        ...state,
        userAddress: action.payload,
      };
    case SET_SELECTED_MERCHANTS:
      return {
        ...state,
        selectedMerchants: action.payload,
      };
    case SET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    case SET_MERCHANTS:
      return {
        ...state,
        merchants: action.payload,
      };
    default:
      return state;
  }
};

export default data;
